import Vue from 'vue';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AxiosPromise } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import truncate from 'lodash/truncate';
import uniq from 'lodash/uniq';
import store from '@/store';
import { UITab } from '@/types';
import {
  DocumentationSection,
  DraftListingModule,
  DraftListingVersion,
  GetRegistrationsResponseData,
  ListingType,
  ListingTypeCategory,
  ListingTypeTemplate,
  SavedListingModule,
} from '@/types/listings.types';
import { ProjectUser } from '@/types/projects.types';
import * as ListingsAPI from '@/api/listings.api';
import * as PublishingAPI from '@/api/publishing.api';
import {
  GetRegistrationVersionsResponseBody,
  GithubRepo,
  MyListing,
  PublishingMode,
} from '@/types/publishing.types';
import { First } from '@/types/util.types';
import { ListingTypes } from '@/constants';
import { FormField } from '@/components/form/form.types';
import { logger } from '@/utils/logger';
import { getRepoFiles } from '@/api/github.api';
import { isValid } from '@/components/form/utils';
import { processDetails } from '@/utils/listings';
import { ProjectTeam } from '@/types/teams.types';

export interface ProductCardI {
  title: string;
  description: string;
}

interface SelectProductApiDraft {
  listingTypeId: number;
  registrationId: number;
  registrationVersionId: number;
}

interface ProductCardDetails {
  listingName: ProductCardI['title'];
  listingDescription: ProductCardI['description'];
}

@Module({ dynamic: true, store, name: 'publishing', namespaced: true })
class Publishing extends VuexModule {
  public mode = {
    edit: false,
    version: false,
  };
  public listingTypes: ListingType[] = [];
  public myListings: MyListing[] = [];
  public listingTypeTemplate: ListingTypeTemplate = getEmptyListingTypeTemplate();
  public statusList: UITab[] = getInitialFlowState(this.mode, 0);
  public activeTab: UITab = this.statusList[0];
  public registrationOptions: GetRegistrationsResponseData[] = [];
  private _draftListing: DraftListingModule = {
    listingName: '',
    listingDescription: '',
    categoryId: 0,
    businessUnitId: 0,
    isPrivate: false,
    listingTypeId: 0,
    registrationId: 0,
    registrationVersionId: 0,
    hasImage: false,
    extendedProperties: {
      githubRepo: null,
      image: '',
      details: cloneDeep(this.listingTypeTemplate.publishing.details.form.fields),
      documentationSections: cloneDeep(
        this.listingTypeTemplate.publishing.documentationSections.defaultSections,
      ),
      keywords: [],
    },
    listingUsers: [],
    teams: [],
    publishMessage: '',
    approvalRequired: false,
  };
  public initialListingState = '';

  public get draftListing(): DraftListingModule {
    return cloneDeep(this._draftListing);
  }

  get isChanged() {
    return JSON.stringify(this._draftListing) !== this.initialListingState;
  }

  public registrationVersions: GetRegistrationVersionsResponseBody = {
    count: 0,
    versions: [],
  };

  public registrationHasVersions = false;

  public get publishEnabled(): boolean {
    const d = this.draftListing;
    const docs = d.extendedProperties.documentationSections;
    const isApi = d.listingTypeId === ListingTypes.API;
    const ds = docs.find(s => !!(s.sectionInfo.sectionContentUrl || s.sectionInfo.sectionContent));
    // we do not compare against the template because we have some
    // one off custom doc sections that don't follow the template
    const docsHaveContent = docs.every(
      s => !!s.sectionInfo.sectionContentUrl || !!s.sectionInfo.sectionContent,
    );
    const hasName = !!d.listingName;
    const hasDescription = !!d.listingDescription;
    const hasDocs = !!docs.length;
    if (!ds) {
      return false;
    }
    const conditions: boolean[] = [
      // this.isChanged,
      hasName,
      hasDescription,
      hasDocs,
      docsHaveContent,
      // hasCat,
      // hasBu,
    ];
    if (isApi) {
      conditions.push(!!d.registrationId);
      if (!this.mode.edit) {
        conditions.push(!!d.registrationVersionId);
      }
    }
    if (d.extendedProperties.details.length) {
      conditions.push(isValid(d.extendedProperties.details));
    }

    return conditions.every(c => c);
  }

  public get listingTypeCategories(): ListingTypeCategory[] {
    const typeId = this.draftListing.listingTypeId;
    const foundType = this.listingTypes.find(lt => lt.listingTypeId === typeId)?.category;
    return foundType ?? [];
  }

  public get saveDraftEnabled(): boolean {
    const d = this.draftListing;
    const isApi = d.listingTypeId === ListingTypes.API;
    const conditions:any[] = [this.isChanged, d.listingName];
    if (isApi) {
      // registrations only exist for API Type
      conditions.push(d.registrationId);
      if (!this.mode.edit) {
        // registrationVersionId isn't selected in edit mode
        // versioning
        conditions.push(d.registrationVersionId);
      }
    }
    return conditions.every(c => !!c);
  }

  public get documentationSectionsMeta() {
    return this.listingTypeTemplate.publishing.documentationSections;
  }

  public get linkedGithubRepoValid() {
    const repoInfo = this.listingTypeTemplate.publishing.githubRepo;
    const repo = this._draftListing.extendedProperties.githubRepo;
    if (repoInfo.required) {
      return !!repo;
    }
    return true;
  }

  public get documentationSections(): DocumentationSection[] {
    return this._draftListing.extendedProperties.documentationSections;
  }

  @Mutation
  public setLinkedGithubRepo({
    repo,
    files,
  }: {
    repo: GithubRepo | null;
    files: Set<string> | null;
  }) {
    const oldRepo = this._draftListing.extendedProperties.githubRepo;
    this._draftListing.extendedProperties.githubRepo = repo;
    const docs = this._draftListing.extendedProperties.documentationSections;
    // conditions to update the name
    // listing name is empty
    // changing repo and old repo name was being used as listing name
    // and never change it in edit mode because the user has already explicitly saved the prev name
    const shouldUpdateName =
      !this.mode.edit &&
      (this._draftListing.listingName === '' || oldRepo?.name === this._draftListing.listingName);

    if (repo !== null && shouldUpdateName) {
      this._draftListing.listingName = truncate(repo.name, { length: 50 });
      this._draftListing.listingDescription = truncate(repo.description, { length: 500 });
    }
    // clear the linked file if it doesn't exist in the newly linked repo
    if (this.listingTypeTemplate.publishing.githubRepo.canLink) {
      // when the user is locked into linking to enterprise GH
      docs.forEach(doc => {
        if (doc.sectionInfo.sectionContentUrl) {
          if (repo === null) {
            doc.sectionInfo.sectionContentUrl = '';
          } else if (files) {
            if (!files.has(doc.sectionInfo.sectionContentUrl)) {
              doc.sectionInfo.sectionContentUrl = '';
            }
          }
        }
      });
    }
  }

  @Mutation
  public setListingTypes(listingTypes: ListingType[]): void {
    this.listingTypes = listingTypes;
  }

  @Mutation
  public setMode(payload: { mode: PublishingMode; initial: boolean }): void {
    const listingTypeId = this._draftListing.listingTypeId;
    this.mode = payload.mode;
    this.statusList = getInitialFlowState(payload.mode, listingTypeId);
    if (!this.statusList.find(t => t.key === this.activeTab.key)) {
      this.activeTab = this.statusList[0];
    }
    if (payload.initial) {
      this.activeTab = this.statusList[0];
    }
  }

  public get isCustomComponent() {
    return this.listingTypeTemplate.publishing.details.isCustomComponent;
  }

  @Mutation
  public setDetailsForm(form: FormField[]) {
    this._draftListing.extendedProperties.details = form;
  }

  @Mutation
  public setDocumentationSection(payload: {
    documentationSection: DocumentationSection;
    index: number;
  }): void {
    Vue.set(
      this._draftListing.extendedProperties.documentationSections,
      payload.index,
      payload.documentationSection,
    );
  }

  @Mutation
  public moveDocumentationSection(payload: { index: number; direction: 'up' | 'down' }): void {
    const { direction, index: i } = payload;
    const lastIndex = this._draftListing.extendedProperties.documentationSections.length - 1;
    // up being smaller index, but it sounded right...
    const isUp = direction === 'up';
    if ((isUp && i < 1) || (!isUp && i >= lastIndex)) {
      // out of bounds
      return;
    }
    let targetIndex = i;
    isUp ? targetIndex-- : targetIndex++;
    const toMove = this._draftListing.extendedProperties.documentationSections[payload.index];
    const swapTarget = this._draftListing.extendedProperties.documentationSections[targetIndex];
    if (toMove.meta.canReorder && swapTarget.meta.canReorder) {
      Vue.set(this._draftListing.extendedProperties.documentationSections, i, swapTarget);
      Vue.set(this._draftListing.extendedProperties.documentationSections, targetIndex, toMove);
    }
  }

  @Mutation
  public addDocumentationSection(documentationSection: DocumentationSection): void {
    this._draftListing.extendedProperties.documentationSections.push(documentationSection);
  }

  @Mutation
  public removeDocumentationSection(index: number): void {
    Vue.delete(this._draftListing.extendedProperties.documentationSections, index);
  }

  @Mutation
  public updateStatusList(statusList: UITab[]): void {
    this.statusList = statusList;
  }

  @Mutation
  public updateActiveTab(newTab: UITab): void {
    this.activeTab = newTab;
  }

  @Mutation
  public setMyListings(myListings: MyListing[]): void {
    this.myListings = myListings
      .map(l => {
        l.versions = l.versions || [];
        return l;
      });
    
  }

  @Mutation
  public setAvailableRegistrations(registrations: GetRegistrationsResponseData[] = []): void {
    this.registrationOptions = registrations || [];
  }

  @Mutation
  public updateDraftListing(draft: DraftListingModule): void {
    this._draftListing = draft;
  }

  @Mutation
  public setSelectedRegistrationVersions(
    registrationVersions: GetRegistrationVersionsResponseBody,
  ): void {
    this.registrationVersions = registrationVersions;
    this.registrationHasVersions = registrationVersions.count > 0;
  }

  @Mutation
  public setCurrentUserInfo(user: ProjectUser): void {
    const newUser = {
      roleName: 'owner',
      roleId: 1,
      mudId: user.mudId,
      fullName: user.fullName,
      email: user.email,
    };
    this._draftListing.listingUsers.push(newUser);
  }

  @Mutation
  public addUsersWithPermissions(users: ProjectUser[]): void {
    this._draftListing.listingUsers = users;
  }

  @Mutation
  public addTeamsWithPermissions(teams: ProjectTeam[]): void {
    this._draftListing.teams = teams;
  }

  @Mutation
  public addApiDetailsToDraft(listingDraft: SelectProductApiDraft): void {
    const { listingTypeId, registrationId, registrationVersionId } = listingDraft;
    this._draftListing.listingTypeId = listingTypeId;
    this._draftListing.registrationId = registrationId;
    this._draftListing.registrationVersionId = registrationVersionId;
  }

  @Mutation
  public addProductCardDetailsToDraft(cardDetails: ProductCardDetails): void {
    const { listingName, listingDescription } = cardDetails;
    this._draftListing.listingName = listingName;
    this._draftListing.listingDescription = listingDescription;
  }

  @Mutation
  public setDraftListing(draft: DraftListingModule): void {
    this._draftListing = draft;
  }

  @Mutation
  public updateRegistrationIdAndProductLabels(registrationId: number): void {
    this._draftListing.registrationId = registrationId;
    this.registrationOptions.some((option): boolean => {
      if (option.registrationId === registrationId) {
        this._draftListing.listingName = option.resourceName;
        this._draftListing.listingDescription = option.resourceDescription;
        return true;
      }
      return false;
    });
  }

  @Mutation
  public setRegistrationVersionId(id: number): void {
    if (id !== 0) {
      this._draftListing.registrationVersionId = id;
    }
  }

  @Mutation
  public goToNextComponent(): void {
    const i = this.statusList.findIndex((tab): boolean => tab.key === this.activeTab.key);
    const nextIndex = i + 1;
    const lastIndex = this.statusList.length - 1;
    if (i === -1 || i === lastIndex) {
      return;
    } else {
      const nextTab = this.statusList[nextIndex];
      nextTab.disabled = false;
      this.activeTab = nextTab;
    }
  }

  @Mutation
  public resetPublishingState(): void {
    this.mode = { edit: false, version: false };
    this.statusList = getInitialFlowState(this.mode, 0);
    this.activeTab = this.statusList[0];
    this.registrationOptions = [];
    this.registrationVersions = {
      count: 0,
      versions: [],
    };
    this.registrationHasVersions = false;
    this.listingTypeTemplate = getEmptyListingTypeTemplate();

    this._draftListing = {
      listingName: '',
      listingDescription: '',
      categoryId: 0,
      businessUnitId: 0,
      isPrivate: false,
      listingTypeId: 0,
      registrationId: 0,
      registrationVersionId: 0,
      hasImage: false,
      extendedProperties: {
        githubRepo: null,
        image: '',
        documentationSections: cloneDeep(
          this.listingTypeTemplate.publishing.documentationSections.defaultSections,
        ),
        keywords: [],
        details: [],
      },
      listingUsers: [],
      teams: [],
      publishMessage: '',
    };
  }

  @Mutation
  public setListingTypeId(value: string): void {
    this._draftListing.listingTypeId = Number(value);
  }

  @Mutation
  public createExtendedPropertiesFromTemplate(listingTypeTemplate: ListingTypeTemplate): void {
    this.listingTypeTemplate = listingTypeTemplate;
    this._draftListing.extendedProperties.documentationSections = cloneDeep(
      listingTypeTemplate.publishing.documentationSections.defaultSections,
    );
    this._draftListing.extendedProperties.details = cloneDeep(
      listingTypeTemplate.publishing.details.form.fields,
    );
  }

  @Mutation
  public setListingImage(image: string) {
    Vue.set(this._draftListing.extendedProperties, 'image', image);
  }

  @Mutation
  public updateListing(payload: Partial<DraftListingModule>): void {
    this._draftListing = { ...this._draftListing, ...payload };
  }

  @Mutation
  public updateListingBusinessUnit(bu: number | string | null): void {
    if (bu) {
      this._draftListing.businessUnitId = Number(bu);
    }
  }

  @Mutation
  public updateListingCategory(categoryId: string | number): void {
    this._draftListing.categoryId = Number(categoryId);
  }

  @Mutation
  public updateListingName(name: string): void {
    this._draftListing.listingName = name;
  }

  @Mutation
  public updateListingDescription(words: string): void {
    this._draftListing.listingDescription = words;
  }

  @Mutation
  public updateKeywords(keywords: string[]): void {
    this._draftListing.extendedProperties.keywords = uniq(keywords);
  }

  @Mutation
  public setEditListing(listing: SavedListingModule): void {
    this._draftListing.listingName = listing.listingName;
    this._draftListing.listingDescription = listing.listingDescription;
    this._draftListing.listingTypeId = listing.listingTypeId;
    this._draftListing.registrationId = listing.registrationId || 0;
    this._draftListing.registrationVersionId = listing.registrationVersionId || 0;
    this._draftListing.businessUnitId = listing.businessUnitId || 0;
    this._draftListing.categoryId = listing.categoryId || 0;
    this._draftListing.isPrivate = listing.isPrivate || false;
    const listingType = this.listingTypes.find(lt => lt.listingTypeId === listing.listingTypeId);
    if (!listingType) {
      throw new TypeError(`Cannot find ListingType: ${listing.listingTypeId}`);
    }
    this.listingTypeTemplate = listingType.template;
    this._draftListing.extendedProperties = listing.extendedProperties;
    this._draftListing.listingUsers = listing.listingUsers;
    this._draftListing.teams = listing.teams;
    this._draftListing.approvalRequired = listing.approvalRequired;

    if (!listingType.template.publishing.details.isCustomComponent) {
      this._draftListing.extendedProperties.details = processDetails(
        listing.extendedProperties.details,
        listingType.template,
      );
    }

    this.initialListingState = JSON.stringify(this._draftListing);
  }

  @Mutation
  public setInitialListingState() {
    this.initialListingState = JSON.stringify(this._draftListing);
  }

  @Action({ commit: 'setLinkedGithubRepo', rawError: true })
  public async linkGithubRepo(
    repo: GithubRepo | null,
  ): Promise<First<Parameters<Publishing['setLinkedGithubRepo']>>> {
    if (repo === null) {
      return {
        repo: null,
        files: null,
      };
    }

    const { githubRepo, documentationSections } = this._draftListing.extendedProperties;
    if (githubRepo) {
      // already attached a repo
      if (documentationSections.some(ds => !!ds.sectionInfo.sectionContentUrl)) {
        // some doc sections have already been linked to the repo
        // get the file list of the new repo so the prev linked files can be validated
        const [owner, name] = repo.full_name.split('/');
        return getRepoFiles(owner, name).then(res => {
          return {
            files: new Set(res.data),
            repo: repo,
          };
        });
      }
    }

    return {
      repo: repo,
      files: null,
    };
  }

  @Action({ commit: 'setListingTypes' })
  public async getListingTypes(): Promise<ListingType[]> {
    return PublishingAPI.getListingTypes().then(r =>
      r.data.map((l): ListingType => {
        return {
          listingTypeId: l.listingTypeId,
          name: l.listingTypeName,
          template: l.listingTemplate,
          category: l.category,
        };
      }),
    );
  }

  @Action({ commit: 'setMyListings' })
  public async getMyListings(): Promise<MyListing[]> {
    return await PublishingAPI.getMyListings().then(res => res.data.listings || []);
  }

  @Action({ commit: 'setMyListings' })
  public async getAllListings(): Promise<MyListing[]> {
    return await PublishingAPI.getAllListings().then(res => res.data.listings || []);
  }

  @Action({ commit: 'setEditListing', rawError: true })
  public async getListing(payload: {
    id: string | number;
    isVersion?: boolean;
  }): Promise<SavedListingModule | DraftListingVersion> {
    let p: AxiosPromise<SavedListingModule | DraftListingVersion>;
    if (payload.isVersion) {
      p = PublishingAPI.getDraftListingVersion(payload.id);
    } else {
      p = PublishingAPI.getDraftListing(payload.id);
    }
    return p.then(r => r.data);
  }

  @Action({ rawError: true })
  public async deleteDraftListingVersion(
    payload: First<Parameters<typeof PublishingAPI.deleteDraftListingVersion>>,
  ): Promise<boolean> {
    return PublishingAPI.deleteDraftListingVersion(payload).then(r => r.data);
  }

  @Action({ rawError: true })
  public async deleteDraftListing(
    payload: First<Parameters<typeof PublishingAPI.deleteDraftListing>>,
  ): Promise<boolean> {
    return PublishingAPI.deleteDraftListing(payload).then(r => r.data);
  }

  @Action({ rawError: true })
  public async discardDraftListingVersion(
    payload: First<Parameters<typeof PublishingAPI.discardDraftListingVersion>>,
  ): Promise<boolean> {
    return PublishingAPI.discardDraftListingVersion(payload).then(r => r.data);
  }

  @Action({ rawError: true })
  public async discardDraftListing(
    payload: First<Parameters<typeof PublishingAPI.discardDraftListing>>,
  ): Promise<boolean> {
    return PublishingAPI.discardDraftListing(payload).then(r => r.data);
  }

  @Action({ commit: 'setAvailableRegistrations' })
  public async getAvailableRegistrations(): Promise<GetRegistrationsResponseData[]> {
    return (await ListingsAPI.getAvailableRegistrations()).data.data || [];
  }

  @Action({ commit: 'setSelectedRegistrationVersions' })
  public async getRegistrationVersions(
    registrationId: number,
  ): Promise<GetRegistrationVersionsResponseBody> {
    return (await PublishingAPI.getRegistrationVersions(registrationId)).data;
  }
}

function getEmptyListingTypeTemplate(): ListingTypeTemplate {
  return {
    publishing: {
      content: {
        productCard: {},
        details: {
          edit: {},
        },
        documentation: {},
      },
      githubRepo: {
        canLink: false,
        required: false,
        repo: null,
      },
      info: {
        acceptImage: false,
      },
      details: {
        isCustomComponent: false,
        form: {
          fields: [],
        },
      },
      documentationSections: {
        canLinkContent: false,
        canEditContent: false,
        maxSections: 0,
        canAddSections: false,
        allowedSectionTypes: [],
        defaultSections: [
          {
            sectionInfo: {
              sectionName: '',
              sectionTemplateType: '',
              sectionTemplateTypeId: 0,
              sectionContent: '',
            },
            meta: {
              canDelete: true,
              canRename: false,
              canReorder: true,
              canLinkContent: true,
              canEditContent: true,
            },
          },
        ],
      },
    },
  };
}

function getInitialFlowState(mode: PublishingMode, listingTypeId: number) {
  const alt = mode.version || mode.edit;
  if (alt && listingTypeId < 1) {
    logger.error('Publishing tabs set in edit mode before listingTypeId loaded');
  }
  const out = [
    {
      text: alt ? 'Details' : 'Select Listing Type',
      key: 'select-api',
      disabled: true,
      component: 'ProductDetails',
    },
    {
      text: alt ? 'Product Card' : 'Build Product Listing',
      key: 'product-card',
      disabled: true,
      component: 'ProductCard',
    },
    {
      text: alt ? 'Documentation' : 'Add Documentation',
      key: 'documentation',
      disabled: true,
      component: 'AddDocumentation',
    },
  ];
  if (mode.edit) {
    if (ListingTypes.API === listingTypeId) {
      // api is a special case where the first tab doesn't have anything for edit mode
      out.shift();
    }
    out.forEach(o => {
      o.disabled = false;
    });
  } else if (mode.version) {
    out[0].disabled = false;
  } else {
    out[0].disabled = false;
  }
  return out;
}

export const PublishingModule = getModule(Publishing);
